*, *:after, *:before { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }
.clearfix:before, .clearfix:after { content: ''; display: table; }
.clearfix:after { clear: both; }

body {
	padding: 0;
	margin: 0;
}

#hero {
	position: relative;
	width: 100%;
	height: 700px;
	background-image: url("../img/hero-img.png");
	background-position: center;

	#content {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		max-width: 768px;
		height: auto;
		.title {
			position: relative;
			width: 100%;
			font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
			font-size: 42px;
			text-align: center;
			line-height: 52px;
			color: #ffffff;
			font-weight: 300;
			float: left;
			margin-bottom: 24px;
			letter-spacing: 1px;
		}
		.sub-title {
			position: relative;
			width: 100%;
			font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
			font-size: 18px;
			text-align: center;
			color: #709be7;
			font-weight: 400;
			float: left;
		}
	}
}

#navbar {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 80px;

	.logo {
		position: absolute;
		top: 50%;
		left: 60px;
		transform: translateY(-50%);
		width: auto;
		height: auto;
		float: left;
		transition: 0.3s ease;
		&:hover {
			opacity: 0.7;
		}
	}
}

#btn-section {
	position: relative;
	width: 100%;
	height: 50px;
	float: left;
	margin-top: 48px;

	.btn {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		width: 200px;
		height: 50px;
		font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
		font-size: 16px;
		text-align: center;
		font-weight: 500;
		line-height: 50px;
		letter-spacing: 1px;
		border-radius: 5px;
		cursor: pointer;
		transition: 0.3s ease;
	}
	.white {
		color: #1a47b0;
		background-color: #ffffff;
		box-shadow: 0 20px 40px 0 rgba(0,0,0,0.30);
		&:hover {
			box-shadow: 0 14px 30px 0 rgba(0,0,0,0.20);
		}
	}
	.blue {
		color: #ffffff;
		background: #10337a;
		background: -moz-linear-gradient(45deg, #10337a 0%, #143c99 100%);
		background: -webkit-linear-gradient(45deg, #10337a 0%,#143c99 100%);
		background: linear-gradient(45deg, #10337a 0%,#143c99 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#10337a', endColorstr='#143c99',GradientType=1 );
		box-shadow: 0 10px 30px 0 rgba(0,0,0,0.20);
		&:hover {
			box-shadow: 0 6px 30px 0 rgba(0,0,0,0.20);
		}
	}
}

#services {
	position: relative;
	width: 100%;
	height: auto;
	background-image: url("../img/services-bg.png");
	background-position: center;
	.primary {
		position: relative;
		width: 1170px;
		height: auto;
		margin: 0 auto;
		margin-top: 128px;
		margin-bottom: 128px;
	}
	.secondary {
		position: relative;
		width: 876px;
		height: auto;
		margin: 0 auto;
		margin-top: 128px;
		margin-bottom: 128px;
	}
	.card {
		position: relative;
		width: 260px;
		height: 200px;
		margin: 16px;
		background-color: #ffffff;
		box-shadow: 10px 20px 40px 0 rgba(0,0,0,0.10);
		border-radius: 5px;
		float: left;
		.title {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			width: calc(100% - 48px);
			height: auto;
			font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
			font-size: 18px;
			text-align: center;
			color: #0f358e;
			font-weight: 400;
			float: left;
		}
	}
}

hr {
	width: 1170px;
	background-color: rgb(15,52,142);
	border-color: rgb(15,52,142);
	opacity: 0.1;
}

#footer {
	position: relative;
	width: 100%;
	height: 300px;
	background-image: url("../img/footer-bg.png");
	background-position: right;

	.address {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		max-width: 320px;
		height: auto;
		font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
		font-size: 18px;
		text-align: center;
		color: #ffffff;
		font-weight: 300;
		line-height: 32px;
	}
}

#contact {
	position: relative;
	width: 100%;
	height: auto;
	background-image: url("../img/contact-hero.png");
	background-position: center;
	padding: 128px 0;

	.form-content {
		position: relative;
		width: 1170px;
		height: auto;
		margin: 0 auto;

		.text {
			position: absolute;
			top: 128px;
			left: 0;
			max-width: 468px;
			height: auto;
			float: left;

			.title {
				position: relative;
				width: 100%;
				font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
				font-size: 42px;
				text-align: left;
				line-height: 52px;
				color: #ffffff;
				font-weight: 300;
				float: left;
				margin-top: 24px;
				letter-spacing: 1px;
			}
			.label {
				position: relative;
				width: 100%;
				font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
				font-size: 18px;
				text-align: left;
				color: #709be7;
				font-weight: 400;
				float: left;
			}
		}
		.form {
			position: relative;
			width: 468px;
			height: auto;
			float: right;
			background: #ffffff;
			box-shadow: 40px 40px 80px 0 rgba(2,8,22,0.30);
			border-radius: 5px;
			padding: 32px;

			.input-fields {
				position: relative;
				width: 404px;
				height: auto;

				input {
					height: 48px;
					border-radius: 5px;
					border: 1px solid #DDDFE7;
					padding: 0 16px;
					line-height: 48px;
					font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
					font-weight: 300;
					font-size: 14px;
					color: #1A1C22;
					float: left;
					margin-top: 16px;
					transition: 0.3s ease;
				}
				.small {
					width: 194px;
					margin-top: 0 !important;
				}
				.large {
					width: 404px;
				}
				textarea {
					height: 144px;
					border-radius: 5px;
					border: 1px solid #DDDFE7;
					padding: 0 16px;
					line-height: 48px;
					font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
					font-weight: 300;
					font-size: 14px;
					color: #1A1C22;
					float: left;
					margin-top: 16px;
					resize: none;
					transition: 0.3s ease;
				}
				::-webkit-textarea-placeholder, ::-webkit-input-placeholder { /* Chrome */
					color: #9398A4;
				}
				textarea:focus, input:focus{
					outline: none;
					border: 1px solid #0F358E;
				}
				.btn-contact {
					width: 404px;
					height: 50px;
					font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
					font-size: 16px;
					text-align: center;
					font-weight: 500;
					line-height: 50px;
					letter-spacing: 1px;
					border-radius: 5px;
					cursor: pointer;
					float: left;
					margin-top: 32px;
					transition: 0.3s ease;
					color: #ffffff;
					background: #10337a;
					background: -moz-linear-gradient(45deg, #10337a 0%, #143c99 100%);
					background: -webkit-linear-gradient(45deg, #10337a 0%,#143c99 100%);
					background: linear-gradient(45deg, #10337a 0%,#143c99 100%);
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#10337a', endColorstr='#143c99',GradientType=1 );
					box-shadow: 0 10px 30px 0 rgba(0,0,0,0.20);
					&:hover {
						box-shadow: 0 6px 30px 0 rgba(0,0,0,0.20);
					}					
				}
			}
		}
	}
}